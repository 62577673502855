<template>
  <el-drawer append-to-body
    title="进项发票"
    :visible.sync="visible"
    :with-header="false"
    size="850px"
    direction="rtl"
    @close="$emit('update:show', false)"
  >
    <div class="details">
      <div class="invoice-title">
        {{handleValueToLabel('InvoiceType',invoice.invoiceType)}}
      </div>
      <div class="invoice-top">
        <label-span label="发票代码：" :span="invoice.invoiceCode"/>
        <label-span label="发票号码：" :span="invoice.invoiceNo"/>
        <label-span label="校验码后六位：" :span="checkCodeLastSix"/>
        <label-span label="发票状态：" :span="handleInvoiceStatus(invoice.invoiceStatus)"/>
      </div>
      <div class="invoice-middle">
        <div class="middle-average">
          <div class="first-row">
            <span>购买方</span>
          </div>
          <div class="second-row">
            <label style="min-width: 42px">名称:</label>
            <div>{{invoice.buyerName}}</div>
          </div>
          <div class="other-row">
            <label-span label="税号：" :span="invoice.buyerTaxNo"></label-span>
          </div>
          <div class="other-row">
            <label>地址电话:</label>
            <el-tooltip effect="dark" :content="invoice.buyerAddressTel" placement="top-start">
              <span style="max-width: 18em;">{{invoice.buyerAddressTel}}</span>
            </el-tooltip>
          </div>
          <div class="other-row">
            <label style="min-width: 98px">开户行及账号：</label>
            <el-tooltip effect="dark" :content="invoice.buyerBankAccount" placement="top-start">
              <span style="max-width: 17em;">{{invoice.buyerBankAccount}}</span>
            </el-tooltip>
          </div>
          <div class="second-row">
            <label style="min-width: 42px">数电发票号码:</label>
            <div>{{invoice.eInvoiceNo}}</div>
          </div>
        </div>
        <div class="middle-average">
          <div class="first-row">
            <span>销售方</span>
          </div>
          <div class="second-row">
            <label style="min-width: 42px">名称:</label>
            <div>{{invoice.sellerName}}</div>
          </div>
          <div class="other-row">
            <label-span label="税号：" :span="invoice.sellerTaxNo"></label-span>
          </div>
          <div class="other-row">
            <label>地址电话:</label>
            <el-tooltip effect="dark" :content="invoice.sellerAddressTel" placement="top-start">
              <span style="max-width: 18em;">{{invoice.sellerAddressTel}}</span>
            </el-tooltip>
          </div>
          <div class="other-row">
            <label style="min-width: 98px">开户行及账号：</label>
            <el-tooltip effect="dark" :content="invoice.sellerBankAccount" placement="top-start">
              <span style="max-width: 17em;">{{invoice.sellerBankAccount}}</span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="invoice-bottom">
        <span>开票明细</span>
        <div class="bottom-table">
          <el-table
            :data="tableData"
            border
            stripe
            :span-method="arraySpanMethod"
            :header-cell-style="handleHeaderCellStyle"
            max-height="280"
            style="width:100%"
          >
            <el-table-column v-if="showCar" prop="goodName" label="货物或应税服务名称" show-overflow-tooltip/>
            <el-table-column v-if="showCar" prop="general" label="规格型号" show-overflow-tooltip/>
            <el-table-column v-if="showCar" prop="specifications" label="单位" show-overflow-tooltip/>
            <el-table-column v-if="showCar" prop="goodNum" label="数量" show-overflow-tooltip/>
            <el-table-column v-if="showCar" prop="goodPrice" label="单价" show-overflow-tooltip/>
            <el-table-column prop="amount" label="金额" show-overflow-tooltip/>
            <el-table-column prop="taxRate" label="税率" show-overflow-tooltip/>
            <el-table-column prop="taxAmount" label="税额" show-overflow-tooltip/>
          </el-table>
        </div>
      </div>
      <div class="invoice-remark">
        <div class="remark-title">备注</div>
        <div class="remark-content">
          <span v-html="invoice.remark" style="white-space: pre-line;"></span>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import { tableStyle } from '@/util/mixins'

  export default {
    mixins: [tableStyle],
    props: {
      show: {
        type: Boolean,
        default: false
      },
      showCar: {
        type: Boolean,
        default: false
      },
      invoice: {
        type: Object,
        default: () => {
        }
      }
    },
    created () {
      this.handleInvoiceStatusDict()
    },
    data () {
      return {
        visible: this.show,
        invoiceStatus:[]
      }
    },
    watch: {
      show () {
        this.visible = this.show
      }
    },
    computed: {
      tableData () {
        return this.invoice.details
      },
      checkCodeLastSix () {
        let checkCode = this.invoice.checkCode
        if (checkCode == '' || checkCode == null) return '无'
        return this.invoice.checkCode.substring(checkCode.length - 6, checkCode.length)
      }
    },
    methods: {
      arraySpanMethod ({ rowIndex, columnIndex }) {
        const openDetailsLength = this.invoice.details.length
        if (this.showCar) {
          if (rowIndex === openDetailsLength - 1) {
            if (columnIndex === 0) {
              return [1, 1]
            } else if (columnIndex === 1) {
              return [1, 4]
            } else {
              return [1, 3]
            }
          }
        }
      },
      handleInvoiceStatus (value) {
        if(value!= null && value != ''){
          return this.getDictLabel(this.invoiceStatus, value)
        }
        return '无'
      },
      handleInvoiceStatusDict(){
        this.getDicts('invoice_status').then(({ data }) => this.invoiceStatus = [{
          label: '全部',
          value: ''
        }, ...data])
      }
    }
  }
</script>

<style scoped lang="scss">
  .details {
    margin: 24px 24px 0 24px;

    .invoice-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }

    .invoice-top {
      display: flex;
      margin-top: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid #CCCCCC;

      .label-span {
        margin-right: 53px;
      }
    }

    .invoice-middle {
      display: flex;
      margin-top: 32px;
      padding-bottom: 16px;
      border-bottom: 1px solid #CCCCCC;

      .middle-average {
        width: 400px;

        .first-row {
          margin-bottom: 16px;

          span {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }
        }

        .second-row {
          display: flex;
          margin-bottom: 16px;

          label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin-right: 4px;
          }

          div {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            text-align: left;
            margin-right: 20px;
          }
        }

        .other-row {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-right: 20px;

          label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin-right: 4px;
          }

          span {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }

    .invoice-bottom {
      margin-top: 32px;
      height: calc(100% - 225px);
      border-bottom: 1px solid #CCCCCC;
      padding-bottom: 32px;

      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }

      .bottom-table {
        margin-top: 16px;
      }
    }

    .invoice-remark {
      margin-top: 24px;

      .remark-title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin-bottom: 16px;
      }

      .remark-content {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }
  }

  .el-row {
    margin-bottom: 16px;

    &:first-child {
      margin-top: 32px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .label-span {
      span {
        margin-left: 8px;
      }
    }
  }

  ::v-deep .el-drawer__body {
    overflow: auto;
  }
</style>
