<template>
  <div class="content-wrap">
    <div class="content-select">
      <!-- <div class="select-header">
        <div class="header-left">
          <span>进项发票</span>
        </div>
        <div class="header-right">
          <label>当前组织：</label>
          <organization-select :is-add-all="true" :model="organization"/>
        </div>
      </div> -->
      <div class="select-content">
        <query :items="queryItems" :options="equipments" :value="search" @query="handleQuery" @resetForm="resetForm" />
      </div>
    </div>
    <div class="content-main">
      <div class="content-title">
        <div class="title-left">
          <span>票据列表</span>
        </div>
        <div class="title-right">
          <el-button @click="handleBatch(1)" :disabled="!selections.length">生成凭证</el-button>
          <el-button @click="handleBatch(2)" :disabled="!selections.length">关联项目</el-button>
          <el-button :disabled="exportDisabled" @click="handleExportExcel">导出</el-button>
        </div>
      </div>
      <div class="content-table">
        <el-table :data="tableData" stripe border show-summary :summary-method="handleGetSummaries" :header-cell-style="handleHeaderCellStyle" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" fixed="left" />
          <el-table-column type="index" label="序号" width="50" />
          <el-table-column prop="invoiceType" label="发票类型" width="220" :formatter="handleTableValueFormat" />
          <el-table-column prop="invoiceCode" label="发票代码" width="150" />
          <el-table-column prop="eInvoiceNo" label="数电发票号码" min-width="120" />
          <el-table-column prop="invoiceNo" label="发票号码" width="120" />
          <el-table-column prop="taxAmount" label="税额" width="120" />
          <el-table-column prop="sumAmount" label="价税合计" width="120" />
          <el-table-column prop="buyerName" show-overflow-tooltip label="购买方" min-width="220" />
          <el-table-column prop="sellerName" show-overflow-tooltip label="销售方" min-width="220" />
          <el-table-column prop="invoiceStatus" label="发票状态" width="80" :formatter="handleTableValueFormat" />
          <el-table-column prop="invoiceTime" label="开票日期" width="115" :formatter="handleTableValueFormat" />
          <el-table-column prop="projectName" label="所属项目" width="120" />
          <el-table-column label="是否生成凭证" width="120">
            <template slot-scope="scope">
              {{ scope.row.voucherId ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="periodNo" label="会计期间" width="100" />
          <el-table-column label="关联凭证" width="120">
            <template slot-scope="scope"> {{ scope.row.voucherNo ? `记-${scope.row.voucherNo}` : '' }} </template>
          </el-table-column>
          <el-table-column prop="source" label="发票来源" width="100" :formatter="handleTableValueFormat" />
          <el-table-column prop="equipmentExtensionNo" label="税控设备" show-overflow-tooltip min-width="180" :formatter="handleTableValueFormat" />
          <el-table-column prop="payer" label="开票人" width="80" />
          <el-table-column prop="updated" label="更新时间" width="180" />
          <el-table-column prop="operate" label="操作" width="80" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleViewDetails(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="search.page" :page-sizes="[15, 30, 50]" :page-size="search.size" layout="total, sizes, prev, pager, next" :total="total" />
      </div>
    </div>
    <!-- 详情弹窗 -->
    <sales-invoice-details :show.sync="drawer" :invoice="invoice" :showCar="showCar" />
    <!-- 导出弹窗 -->
    <el-dialog append-to-body title="提示" @before-close="handleExportExcelCancel" :visible.sync="exportVisible" width="30%">
      <div class="dialog-body">是否导出当前筛选出来的所有数据？</div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleExportExcelCancel">取 消</el-button>
        <el-button type="primary" @click="handleExportExcelConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 生成凭证 || 关联项目-->
    <el-dialog append-to-body :title="curdialogType == 1 ? '选择凭证模板' : '关联项目'" :visible.sync="chooseVisible" width="600px">
      <div class="dialog-body">
        <div class="voucher_tmpl_box" v-if="curdialogType == 1">
          <el-form class="cus_tmpl_form_box" :model="chooseVouParams" label-width="200px">
            <el-form-item label="凭证日期" prop="voucherDate">
              <el-date-picker v-model="chooseVouParams.voucherDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"> </el-date-picker>
            </el-form-item>
            <el-form-item label="凭证模板" prop="tmplId">
              <el-select v-model="chooseVouParams.tmplId" placeholder="请选择">
                <el-option v-for="item in vouTplList" :key="item.id" :label="item.tmplName" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否合并为一张凭证" prop="isMerge">
              <el-radio-group v-model="chooseVouParams.isMerge">
                <el-radio label="Y" :disabled="isDisRadio">是</el-radio>
                <el-radio label="N" :disabled="isDisRadio">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div class="about_items_box" v-if="curdialogType == 2" style="height: 400px; width: 100%; overflow: auto">
          <div style="text-align: left"><el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width: 330px" suffix-icon="el-icon-search"> </el-input></div>
          <el-tree
            ref="tree"
            default-expand-all
            highlight-current
            :data="treeData"
            :props="{
              children: 'children',
              label: 'projectName'
            }"
            @node-click="handleNodeClick"
            :filter-node-method="filterNode"
          ></el-tree>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="chooseVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDialog">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog append-to-body title="消息提示" :visible.sync="complateVible" width="30%">
      <div style="text-align: left; padding: 20px; font-size: 12px">{{ resComplateInfo.message }}</div>
      <div class="dialog-footer" slot="footer">
        <el-button v-if="resComplateInfo.success" @click="$router.push('/voucher/list')">查看凭证</el-button>
        <!-- <el-button @click="complateVible = false">取 消</el-button> -->
        <el-button type="primary" @click="complateVible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSalesInvoicePoolData, getInvoiceDetails, exportInvoices } from '@/service/invoice-pool';
import { SALES_INVOICE_POOL } from '@/content/query-items';
import SalesInvoiceDetails from './component/sales/SalesInvoiceDetails';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import Query from '@/components/query';
import { changeMethods, pagingMethods, tableStyle } from '@/util/mixins';
import { listEquipmentByOrg } from '@/service/invoice-open';
import { voucherTmplList, voucherBuild, projectName, getProjectTree } from '@/service/voucher';

export default {
  name: 'InvXxfp',
  mixins: [tableStyle, pagingMethods, changeMethods],
  components: { SalesInvoiceDetails, OrganizationSelect, Query },
  data() {
    return {
      filterText: '',
      isDisRadio: true,
      curdialogType: 1,
      chooseVisible: false,
      complateVible: false,
      resComplateInfo: {},
      chooseVouParams: {
        tmplId: '',
        isMerge: 'Y',
        voucherDate: this.$moment().format('yyyy-MM-DD'),
        invoiceIds: ''
      },
      aboutItemsParams: {
        invoiceIds: [],
        vouProjectId: '',
        projectName: ''
      },
      vouTplList: [],
      treeData: [],

      queryItems: SALES_INVOICE_POOL,
      equipments: [],
      organization: {},
      tableData: [],
      invoiceStatus: [],
      invoiceSource: [],
      // 搜索信息
      search: {
        size: 15,
        page: 1,
        isSalInvoice: true,
        equipmentExtensionNo: undefined
      },
      total: 0,
      // 导出按钮
      exportVisible: false,
      exportDisabled: false,
      // 详情信息
      drawer: false,
      // 详情汽车票
      showCar: false,
      invoice: {},
      selections: []
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    selections(val) {
      if (val.length == 1) {
        this.isDisRadio = true;
        this.chooseVouParams.isMerge = 'N';
        return;
      }
      this.isDisRadio = false;
      this.chooseVouParams.isMerge = 'Y';
    },
    tableData(value) {
      this.exportDisabled = value.length ? false : true;
    },
    organization: {
      handler() {
        this.search['equipmentExtensionNo'] = undefined;
        this.handleGetBillingEquipment();
      },
      deep: true
    }
  },
  created() {
    const { voucherId = '' } = this.$route.query;
    if (voucherId) {
      this.search.voucherId = voucherId;
    }

    this.handleInitDictionaries();
    this.handleGetSalesPoolTable();
    this.handleGetBillingEquipment();
    this._voucherTmplList();
    this._getProjectTree();
  },
  methods: {
    _getProjectTree() {
      getProjectTree().then((res) => {
        console.log(res);
        if (res.success) {
          this.treeData = res.data;
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.projectName.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      this.aboutItemsParams.vouProjectId = data.id;
      this.aboutItemsParams.projectName = data.projectName;
    },
    async _voucherTmplList() {
      const { data } = await voucherTmplList({ bizType: 'SI' });
      this.vouTplList = data;
    },
    handleDialog() {
      if (this.curdialogType == 1) {
        if (!this.chooseVouParams.voucherDate) return this.toast('凭证日期不能为空！', 'warning');
        if (!this.chooseVouParams.tmplId) return this.toast('凭证模版不能为空！', 'warning');
        voucherBuild(this.chooseVouParams).then((res) => {
          this.chooseVisible = false;
          this.resComplateInfo = res;
          this.complateVible = true;
          this.handleGetSalesPoolTable();
        });
      } else if (this.curdialogType == 2) {
        if (!this.aboutItemsParams.vouProjectId) return this.toast('请选择关联项目！', 'warning');
        projectName(this.aboutItemsParams).then((res) => {
          res.success && this.toast('操作成功', 'success');
          this.chooseVisible = false;
          this.handleGetSalesPoolTable();
        });
      }
    },
    /* 选中表格信息 */
    handleSelectionChange(value) {
      this.selections = value;
    },
    handleBatch(type) {
      this.curdialogType = type;
      this.chooseVisible = true;
      this.chooseVouParams.voucherDate = this.$moment().format('yyyy-MM-DD');
      const ids = this.selections.map((item) => item.id);
      this.chooseVouParams.invoiceIds = ids;
      this.aboutItemsParams.invoiceIds = ids;
      if (type == 1) {
        this._voucherTmplList();
      } else if (type == 2) {
        this._getProjectTree();
      }
    },
    resetForm() {
      this.search = {
        size: 15,
        page: 1,
        isSalInvoice: true,
        equipmentExtensionNo: undefined
      };
    },
    // 初始化字典值
    handleInitDictionaries() {
      this.getDicts('invoice_status').then(({ data }) => (this.invoiceStatus = data));
      this.getDicts('invoice_source').then(({ data }) => (this.invoiceSource = data));
    },
    // 获取开票设备
    handleGetBillingEquipment() {
      let orgId = this.organization.orgId;
      if (typeof orgId != 'undefined') {
        this.equipments = [];
        listEquipmentByOrg(this.organization.orgId).then((res) => {
          if (res.success) {
            if (res.data.length != 0) {
              let equipments = res.data.map((item) => {
                let equipment = {
                  label: '税盘编号：' + item.extensionNo,
                  value: item.extensionNo
                };
                return equipment;
              });
              this.equipments = equipments;
            }
          }
        });
      }
    },
    // 切换分页数量
    handleSizeChange(val) {
      this.search.size = val;
      this.handleGetSalesPoolTable();
    },
    // 分页
    handleCurrentChange(val) {
      this.search.page = val;
      this.handleGetSalesPoolTable();
    },
    // 查询操作
    handleQuery(value) {
      // 查询条件
      if (typeof value != 'undefined') {
        let invoiceTimeRange = value.invoiceTimeRange;
        if (invoiceTimeRange) {
          value.invoiceTimeStart = invoiceTimeRange[0];
          value.invoiceTimeEnd = invoiceTimeRange[1];
          // delete value.invoiceTimeRange
        }
        this.search = { ...this.search, ...value };
      }
      if (Object.keys(value).length === 0) {
        this.search = {
          size: 15,
          page: 1,
          incomeSalesType: 'sales'
        };
      }
      // 组织信息
      let salesOrgId = this.organization.orgId;
      if (typeof salesOrgId != 'undefined') {
        this.search = { ...this.search, salesOrgId: salesOrgId };
      }
      this.handleGetSalesPoolTable();
    },
    // 刷新表格
    async handleGetSalesPoolTable() {
      // 查询请求
      const { success, data } = await getSalesInvoicePoolData(this.search);
      if (success) {
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    // 表格值格式化
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '发票类型':
          return this.handleValueToLabel('InvoiceType', value);
        case '发票状态':
          if (value != null && value != '') {
            return this.getDictLabel(this.invoiceStatus, value);
          }
          return '--';
        case '开票日期':
          return this.$moment(value).format('YYYY-MM-DD');
        case '发票来源':
          if (value != null && value != '') {
            return this.getDictLabel(this.invoiceSource, value);
          }
          return '--';
        case '税控设备':
          if (value != null && value != '') {
            return '税盘编号：' + value;
          }
          return '--';
        default:
          return value;
      }
    },
    // 格式化合计行
    handleGetSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value)) && [4, 5].indexOf(index) > -1) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number((prev + curr).toFixed(2));
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    // 导出Excel
    handleExportExcel() {
      this.exportVisible = true;
      this.exportDisabled = true;
    },
    // 销项池导出取消
    handleExportExcelCancel() {
      this.exportVisible = false;
      this.exportDisabled = false;
    },
    // 销项池导出确认
    async handleExportExcelConfirm() {
      this.exportVisible = false;
      this.exportDisabled = true;
      const rsp = await exportInvoices(this.search);
      this.exportDisabled = false;
      this.downloadFile(rsp);
    },
    // 查看详情
    handleViewDetails(invoice) {
      this.drawer = true;
      this.showCar = true;
      getInvoiceDetails(invoice.id).then((res) => {
        if (res.success) {
          this.invoice = res.data;
          this.invoice.details.forEach((item) => (item.taxRate = Number(item.taxRate) * 100 + '%'));
          // 机动车显示相应明细 暂无二手车枚举
          if (invoice.invoiceType == 'VehInvoice') {
            this.showCar = false;
          } else {
            // 统计信息
            let sumAmount = this.invoice.details.map((item) => Number(item.amount)).reduce((sum, amount) => sum + amount, 0);
            let sumTaxAmount = this.invoice.details.map((item) => Number(item.taxAmount)).reduce((sum, taxAmount) => sum + taxAmount, 0);
            let total = {
              goodName: '合计',
              amount: Number(sumAmount).toFixed(2),
              taxAmount: Number(sumTaxAmount).toFixed(2)
            };
            this.invoice.details.push(total);
            let sum = Number(sumAmount + sumTaxAmount).toFixed(2);
            let sumBig = this.handleSmallToCapitalize(sum);
            let priceTax = {
              goodName: '价税合计（大写）',
              general: sumBig,
              specifications: '（小写）￥' + sum
            };
            this.invoice.details.push(priceTax);
          }
        }
      });
    },
    // 金额小写转大写
    handleSmallToCapitalize(price) {
      let fraction = ['角', '分'];
      let digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
      let unit = [
        ['圆', '万', '亿'],
        ['', '拾', '佰', '仟']
      ];
      let head = price < 0 ? '负' : '';
      price = Math.abs(price);
      let upper = '';
      for (let i = 0; i < fraction.length; i++) {
        upper += (digit[Math.floor(price * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
      }
      upper = upper || '整';
      price = Math.floor(price);
      for (let i = 0; i < unit[0].length && price > 0; i++) {
        let p = '';
        for (let j = 0; j < unit[1].length && price > 0; j++) {
          p = digit[price % 10] + unit[1][j] + p;
          price = Math.floor(price / 10);
        }
        upper = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + upper;
      }
      return (
        head +
        upper
          .replace(/(零.)*零圆/, '圆')
          .replace(/(零.)+/g, '零')
          .replace(/^整$/, '零圆整')
          .replace('.', '')
      );
    }
  }
};
</script>

<style scoped lang="scss">
.content-select {
  .select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e9e9e9;

    .header-left {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }

    .header-right {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;

      ::v-deep .el-input__inner {
        border: none;
        border-radius: 0;
      }
    }

    .header-left span:before {
      content: '|';
      display: inline-block;
      margin-right: 10px;
      background-color: #333;
    }
  }

  .select-content {
    // display: inline;
    padding: 0;
  }
}

.content-main {
  min-height: calc(100% - 170px);

  .content-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 22px;
  }

  .content-table {
    .el-button {
      padding: 0;
      margin-right: 10px;
    }
  }
}

.dialog-body {
  display: flex;
  margin: 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  text-align: right;
  padding: 0 24px 24px;
}
</style>
<style lang="scss">
.cus_tmpl_form_box {
  text-align: left;
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-form-item__label {
    width: 200px;
  }
}
</style>
